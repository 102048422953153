const lang = {
    "en": {
        "starting_deck": "Deck",
        "starting_cards": "Starting cards",
        "draw_stacking": "Draw Stacking",
        "continue": "Continues",
        "public_lobby": "Public",
        "spectators": "Spectators",
        "enable_chat": "Chat",
        "max_players": "Max Players",
        "xray": "Hands Down",
        "infinite_draw": "Infinite Draw",
        "always_play": "Always Play",

        "normal": "Normal",
        "all_wilds": "All Wilds!",
        "debug": "Debug",

        "off": "Off",
        "matching": "Matching",
        "any": "Any",

        "false": "",
        "true": "Enabled",

        "choose_color": "Choose color"
    }
}

export default lang;